<template>
  <a-drawer
      title="通知内容"
      :visible="visible"
      @close="closeModal"
      width="62.5vw"
      wrapClassName="notification-drawer"
  >
    <div class="box-padding">
      <a-space>
        <a-button type="primary" ghost @click="handleAdd">Add</a-button>
      </a-space>
    </div>
    <div class="forms">
      <a-form class="permsForm" :model="notificationDate" ref="formRef">
        <a-table
            class="mt12"
            :columns="columns"
            :data-source="notificationDate"
            :pagination="false"
            row-key="roleId"
        >
        <template #relationRole="{ index, record}">
            <a-form-item :name="[index, 'roleIdList']"
                         :rules="[{
                             required: true,
                             message: '角色不能为空',
                             trigger: 'change',
                             type: 'array',
                           },
                     ]">
              <SelectWithAll
                  style="width: 100%"
                  v-model:value="record.roleIdList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
              >
                <a-select-option
                    v-for="(item, index) in roleOps"
                    :key="index"
                    :value="item.roleId"
                >
                  {{ item.roleName }}
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
          </template>
          <template #firstMenuList="{ index, record }">
            <a-form-item :name="[index, 'firstMenuList']"
                         :rules="[{
                             required: true,
                             message: '一级菜单不能为空',
                             trigger: 'change',
                             type: 'array',
                           },
                     ]">
              <SelectWithAll
                  style="width: 100%"
                  v-model:value="record.firstMenuList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  @change="firstChange(record)"
              >
                <a-select-option
                    v-for="(item, index) in firstMenuOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
          </template>
          <template #secondMenuList="{ index, record }">
            <a-form-item :name="[index, 'secondMenuList']"
                         :rules="[{
                             required: true,
                             message: '二级菜单不能为空',
                             trigger: 'change',
                             type: 'array',
                           },
                     ]">
              <SelectWithAll
                  style="width: 100%"
                  v-model:value="record.secondMenuList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  @dropdownVisibleChange="secondMenuChange(record)"
                  @change="secordChange(record)"
              >
                <a-select-option
                    v-for="(item, index) in secondMenuOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
          </template>
          <template #content="{ index, record }">
            <a-form-item :name="[index, 'content']"
                         :rules="[{
                             required: true,
                             message: '发布内容不能为空',
                             trigger: 'change',
                           },
                     ]">
              <a-textarea
                  style="width: 100%"
                  placeholder="请输入"
                  :auto-size="{ minRows: 2, maxRows: 20 }"
                  :maxlength="200"
                  showCount
                  v-model:value="record.content"
                  @keyup.enter="(e) => { e.stopPropagation() }"
              />
            </a-form-item>
          </template>
          <template #operation="{ index,record }">
<!--            <a-space>
              <span class="redLink underline" @click="delClick(index,record)"> 删除 </span>
            </a-space>-->
            <ActionBtn class="btnList" v-bind="record" :btnList="btnList" @DeleteOutlined="delClick(index,record)" />
          </template>
        </a-table>
      </a-form>
    </div>
    <div class="header bottom-btn">
      <a-space>
        <a-button type="primary" ghost @click="closeModal">取消</a-button>
        <a-button type="primary" @click="saveClick">保存</a-button>
      </a-space>
    </div>
    <ModalBox v-model="modelValue" :ok="handleOk" :content="content" />
  </a-drawer>
</template>

<script>

import { ref, watch,} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {message} from "ant-design-vue";
import ModalBox from '@/components/ModalBox'
import ActionBtn from '@/components/ActionBtn'

const btnList = [
  {
    name: '删除',
    icon: 'DeleteOutlined',
    auth: []
  },
]
export default {
  name: "NotificationContentDrawer",
  components: {ModalBox,ActionBtn},
  props: {
    visible: Boolean,
    editPermsDate: Object,
    notificationMark: String,
    isContentValue: String,
  },
  emits: ['close-modal', 'save-modal'],
  setup(props,{ emit }){
    const { $api } = useGlobalPropertyHook();
    const formRef = ref()
    const notificationDate = ref([])
    const columns = [
      {
        title: "角色",
        dataIndex: "roleIdList",
        key: "roleIdList",
        ellipsis: true,
        width: 200,
        slots: { customRender: "relationRole" }
      },
      {
        title: "一级菜单",
        dataIndex: "firstMenuList",
        key: "firstMenuList",
        ellipsis: true,
        width: 200,
        slots: { customRender: "firstMenuList" },
      },
      {
        title: "二级菜单",
        dataIndex: "secondMenuList",
        key: "secondMenuList",
        ellipsis: true,
        width: 200,
        slots: { customRender: "secondMenuList" },
      },
      {
        title: "发布内容",
        dataIndex: "content",
        key: "content",
        ellipsis: true,
        slots: { customRender: "content" },
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: 80,
        ellipsis: true,
        slots: {
          customRender: "operation",
        },
      },
    ]

    const roleOps = ref([])
    const roleAllOps = ref([])
    const firstMenuOps  = ref([])
    const secondMenuOps = ref([])
    const firstMenuOpsCopy = ref([])
    const secondMenuOpsCopy = ref([])

    const firstSecondItem = () => {
      $api.getMenu().then((res) => {
        const { firstMenu,secondMenu}  = res.data;
        firstMenuOps.value = firstMenu;
        secondMenuOps.value = secondMenu
        firstMenuOpsCopy.value = firstMenu
        secondMenuOpsCopy.value = secondMenu
      })
    };
    const orgAllList = ref([])
    const getfirstSecondRelations = () => {
      $api.getMenuRelations().then((res) => {
        orgAllList.value = res.data
      })
    }

    const secondMenuChange = (record) => {
      if(record.firstMenuList.length > 0) {
        const temp = {}
        record.firstMenuList.forEach(item => temp[item] = true)
        secondMenuOps.value = orgAllList.value.filter(item => temp[item.firstMenu]).map(item => item.secondMenu)
        //if(secondMenuOps.value.length === 0) record.secondMenuList = record.firstMenuList
      } else {
        secondMenuOps.value = secondMenuOpsCopy.value
      }
    }

    const firstChange = (record) => {
      const temp = {}
      record.firstMenuList.forEach(item => temp[item] = true)
      let a  = orgAllList.value.filter(item => temp[item.firstMenu]).map(item => item.secondMenu)
      record.secondMenuList = record.secondMenuList.filter(item => a.includes(item))
      let b = record.secondMenuList.filter(item => record.firstMenuList.indexOf(item) === -1)
      record.firstMenuList.filter(item => !b.includes(item)).forEach(item => {
        if(!orgAllList.value.map(i => i.firstMenu).includes(item)){
          record.secondMenuList.push(item)
        }
      })
    }
    const secordChange = (record) => {
      const temp = {}
      record.secondMenuList.forEach(item => temp[item] = true)
      let newFirstMenuList = orgAllList.value.filter(item => temp[item.secondMenu]).map(item => item.firstMenu)
      record.firstMenuList = record.firstMenuList.concat(newFirstMenuList).filter((item, index, array) => {
        return array.indexOf(item) === index
      })
    }

    const getRoleIdList = () => {
      $api.getRoleOpsList({status:'all'}).then((res) => {
        roleOps.value = res.data
        roleAllOps.value = res.data
      })
    }

    const modelValue = ref(false)
    const content = ref('')
    const modalData = ref()
    const handleOk = () => {
      const {index} = modalData.value
      notificationDate.value.splice(index, 1);
    }
    const delClick = (index,record) => {
      if(notificationDate.value.length === 1) {
        message.error('通知内容不能为空')
      } else {
        content.value = `确定删除本条数据吗？`
        modalData.value = {index, record}
        modelValue.value = true
        /*Modal.confirm({
        title: `确定删除本条数据吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          console.log(index,record)
          notificationDate.value.splice(index, 1);
        },
      });*/
      }
    };

    const closeModal = () => {
      formRef.value.clearValidate();
      formRef.value.resetFields();
      notificationDate.value = []
      emit("close-modal");
    };

    const saveNotificationContentData = ref('')
    const saveClick = () => {
      Promise.all([formRef.value.validate()]).then(() => {
        if(props.notificationMark) {
          let editParams = []
          if(notificationDate.value.length === 0){
            editParams = [
              {notificationMark:props.notificationMark}
            ]
          } else {
            notificationDate.value = notificationDate.value.map(item => {
              return {
                content:item.content,
                firstMenuList:item.firstMenuList,
                secondMenuList:item.secondMenuList,
                roleIdList:item.roleIdList,
                notificationMark:props.notificationMark
              }
            })
            editParams = [
              ...notificationDate.value
            ]
          }
          $api.updateContent(editParams).then((res) => {
            if (res.code === 200) {
              message.success("保存成功");
              emit("close-modal",notificationDate.value.length)
            }
          })
        } else {
          //新增
          const params = [
            ...notificationDate.value
          ]
          $api.saveNotificationContent(params).then((res) => {
            if (res.code === 200) {
              saveNotificationContentData.value = res.data
              message.success("保存成功");
              emit("close-modal", saveNotificationContentData.value,params);
            }
          })
        }
      });
    }

    const handleAdd = () => {
      let newRow = {
        roleIdList: [],
        firstMenuList:[],
        secondMenuList:[],
        content:[],
      }
      notificationDate.value.push(newRow)
    }

    watch(
        () => props.visible,
        (val) => {
          if(val) {
            if(props.notificationMark) {
              //编辑
              $api.getManualDeatisList(props.notificationMark).then((res) => {
                notificationDate.value = res.data.map(item => {
                  return {
                    roleIdList: item.roleId.split(", "),
                    firstMenuList: item.firstMenu.split(", "),
                    secondMenuList: item.secondMenu.split(", "),
                    content : item.content,
                    id: item.id,
                    notificationMark: item.notificationMark,
                  }
                })
              })
            } else if(props.isContentValue) {
              $api.getManualDeatisList(saveNotificationContentData.value).then((res) => {
                notificationDate.value = res.data.map(item => {
                  return {
                    roleIdList: item.roleId.split(", "),
                    firstMenuList: item.firstMenu.split(", "),
                    secondMenuList: item.secondMenu.split(", "),
                    content : item.content,
                  }
                })
              })
            } else {
              notificationDate.value = []
            }
            firstSecondItem();
            getRoleIdList();
            getfirstSecondRelations();
          }
        }
    )
    return{
      formRef,
      notificationDate,
      columns,
      roleOps,
      roleAllOps,
      firstMenuOps,
      secondMenuOps,
      firstMenuOpsCopy,
      secondMenuOpsCopy,
      closeModal,
      saveClick,
      handleAdd,
      firstSecondItem,
      getRoleIdList,
      getfirstSecondRelations,
      orgAllList,
      secondMenuChange,
      saveNotificationContentData,
      delClick,
      firstChange,
      secordChange,
      modelValue,
      content,
      handleOk,
      btnList
    }
  }
}
</script>

<style scoped lang="less">
.notification-drawer {
  .header {
    display: flex;
    justify-content: flex-end;
  }
  .box-padding{
    padding: 24px 24px 0;
  }
}
</style>
