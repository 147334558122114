<template>
  <a-drawer
    title="通知内容"
    :visible="visible"
    @close="closeModal"
    width="38.5vw"
  >
    <a-table
      :columns="columns"
      :data-source="deatisList"
      :pagination="false"
      row-key="notificationId"
    >
    </a-table>
  </a-drawer>
</template>
<script>
import { defineComponent, ref,onMounted } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";

export default defineComponent({
    name: "DetailsDrawer",
    props: {
        id: String,
    },

    setup(props) {
        const { $api } = useGlobalPropertyHook();

        const columns = [
          {
            title: "角色",
            dataIndex: "roleName",
            key: "roleName",
            width: 80,
            ellipsis: true,
          },
          {
            title: "一级菜单",
            dataIndex: "firstMenu",
            key: "firstMenu",
            width: 100,
            ellipsis: true,
          },
          {
            title: "二级菜单",
            dataIndex: "secondMenu",
            key: "secondMenu",
            width: 100,
            ellipsis: true,
          },
          {
            title: "发布内容",
            dataIndex: "content",
            key: "content",
            width: 150,
            ellipsis: true,
          },
        ];

        const deatisList = ref([]);
        const getList = () => {
            $api.getManualDeatisList(props.id).then((res) => {
                deatisList.value = res.data
            })
        }
        onMounted(()=>{
                getList()
            })

        return {
            deatisList,
            columns,
            getList,
        };
    }
})
</script>
<style scoped lang="less">
 
</style>