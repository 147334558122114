<template>
  <section class="app-container">
    <div>
    <a-tabs v-model:value="activeTab" @change="tabChange">
      <a-tab-pane v-for="{ name, title } in tabList" :key="name" :tab="title">
        <component :is="name" :ref="tabRef" />
      </a-tab-pane>
    </a-tabs>
  </div>
  </section>
</template>

<script>
import { defineComponent, onActivated, ref } from "vue";
import { useGlobalPropertyHook } from "../../../../hooks/common";
import Announcement from "./Announcement/index";
import ManualNotifications from "./ManualNotifications/index";

export default defineComponent({
    name: "upgradeNotice",
    components: {
      ManualNotifications,
      Announcement,
    },
    setup(props, ctx) {
        const { $EventBus } = useGlobalPropertyHook();
        const activeTab = ref("ManualNotifications");
        const tabRef = ref();

        const tabChange = () => {
            $EventBus.emit("refresh");
        };

        const tabList = ref([
            {
              name: "ManualNotifications",
              title: "手动通知",
            },
            {
              name: "Announcement",
              title: "公告",
            },
        ]);

        onActivated(() => {
            ctx.emit("refresh-table");
        });

        return {
            tabList,
            tabRef,
            activeTab,
            tabChange,
        };
    },
});
</script>

<style scoped lang="less">
@import "~@/style/newTitleTab.less";
.app-container {
  // height: 100%;
  overflow: auto;

  :deep(.ant-tabs) {
    margin: 0 16px 16px;

    .ant-tabs-tabpane {
      background-color: #fff;
      min-height: 75vh;
    }
  }
}
</style>
