<template>
  <a-drawer
      wrapClassName="addEdit-drawer"
      width="38.5vw"
      :title="title"
      :visible="visible"
      @close="handleCancel"
      @update:visible="(val) => $emit('update:visible', val)"
      @mouseenter="isOpenEmails"
  >
    <div class="forms">
      <a-form
          class="form"
          ref="formRef"
          :rules="rules"
          :model="formState"
          layout="vertical"
      >
        <a-form-item label="发件人" name="sender" class="form-item-inline">
          <a-input
              v-model:value="formState.sender"
              placeholder="CH, Staffing Management"
              disabled
          />
        </a-form-item>
        <a-form-item label="收件人" name="toEmails" class="form-item-inline">
          <a-select
              placeholder="请选择"
              v-model:value="formState.toEmails"
              mode="multiple"
              :maxTagCount="1"
              show-search
              :open="isOpenToEmails"
              @click="clickToEmails"
              @inputKeyDown="(e) => {e.stopPropagation()}"
          >
            <template #dropdownRender>
              <div style="display: flex; justify-content: space-around" @click="(e) => e.stopPropagation()">
                <SelectWithAll
                    style="width: 16vw; margin: 5px;"
                    v-model:value="formData.roleList"
                    placeholder="Role"
                    mode="multiple"
                    showArrow
                    allowClear
                    :maxTagCount="1"
                >
                  <a-select-option
                      v-for="(item, index) in roleOps"
                      :key="index"
                      :value="item.roleName"
                  ><span :title="item">{{ item.roleName }}</span>
                  </a-select-option>
                </SelectWithAll>
                <SelectWithAll
                    style="width: 16vw; margin: 5px"
                    v-model:value="formState.toEmails"
                    placeholder="Email/GGID"
                    showArrow
                    allowClear
                    mode="multiple"
                    maxTagCount="1"
                    :filterOption="filterOption"
                    @change="toEmailsChange"
                >
                  <a-select-option
                      v-for="item in roleInfosOps"
                      :value="item.fullName + ' ' + item.email"
                      :key="item.ggid"
                  >
                    {{ item.fullName + " " + item.email }}
                  </a-select-option>
                </SelectWithAll>
              </div>
              <div class="select-reviewer">
                <CardTitle size="small" style="margin-left: 10px">选中人员概览</CardTitle>
                <a-table
                    :data-source="chooseRoleList"
                    :columns="columns"
                    rowKey="id"
                    :pagination="false"
                    style="margin: 10px;"
                    :scroll="{
                      y: '35vh',
                      scrollToFirstRowOnChange: true,
                    }"
                >
                  <template #operation="{ record,index }">
                    <a-space>
                      <span class="redLink underline" @click="delClick(record,index)" @mousedown="e => e.preventDefault()"> 删除 </span>
                    </a-space>
                  </template>
                </a-table>
              </div>
            </template>
          </a-select>
        </a-form-item>
        <a-form-item label="抄送人" name="ccEmails" class="form-item-inline">
          <a-select
              placeholder="请选择"
              v-model:value="formState.ccEmails"
              mode="multiple"
              :maxTagCount="1"
              show-search
              :open="isOpenCcEmails"
              @click="ccClickToEmails"
          >
            <template #dropdownRender>
              <div style="display: flex; justify-content: space-around" @click="(e) => e.stopPropagation()">
                <SelectWithAll
                    style="width: 16vw; margin: 5px;"
                    v-model:value="formData.ccRoleList"
                    placeholder="Role"
                    mode="multiple"
                    showArrow
                    allowClear
                    :maxTagCount="1"
                >
                  <a-select-option
                      v-for="(item, index) in ccRoleOps"
                      :key="index"
                      :value="item.roleName"
                  ><span :title="item">{{ item.roleName }}</span>
                  </a-select-option>
                </SelectWithAll>
                <SelectWithAll
                    style="width: 16vw; margin: 5px"
                    v-model:value="formState.ccEmails"
                    placeholder="Email/GGID"
                    showArrow
                    allowClear
                    mode="multiple"
                    :maxTagCount="1"
                    :filterOption="filterOption"
                    @change="ccEmailsChange"
                >
                  <a-select-option
                      v-for="item in ccRoleInfosOps"
                      :value="item.fullName + ' ' + item.email"
                      :key="item.ggid"
                  >
                    {{ item.fullName + " " + item.email }}
                  </a-select-option>
                </SelectWithAll>
              </div>
              <div class="select-reviewer">
                <CardTitle size="small" style="margin-left: 10px">选中人员概览</CardTitle>
                <a-table
                    :data-source="ccChooseRoleList"
                    :columns="columns"
                    rowKey="id"
                    :pagination="false"
                    style="margin: 10px;"
                    :scroll="{
                      y: '35vh',
                      scrollToFirstRowOnChange: true,
                    }"
                >
                  <template #operation="{ record,index }">
                    <a-space>
                      <span class="redLink underline" @click="ccDelClick(record,index)" @mousedown="e => e.preventDefault()"> 删除 </span>
                    </a-space>
                  </template>
                </a-table>
              </div>
            </template>
          </a-select>
        </a-form-item>
        <div class="items">
        <a-form-item label="上线时间" name="launchTime" >
          <a-date-picker
              v-model:value="formState.launchTime"
              :showTime="{ format: 'HH' }"
              format="YYYY-MM-DD HH:00"
              valueFormat="YYYY-MM-DD HH:00"
              placeholder="请选择"
              :disabledDate="disabledDatelaunchTime"
              :disabledTime="disabledTimelaunchTime"
              :showToday="false"
              @openChange="launchOpenChange"
          />
        </a-form-item>
        <a-form-item label="通知类型" name="type">
          <a-input

              v-model:value="formState.type"
              placeholder="升级通知"
              disabled
          />
        </a-form-item>
        </div>
        <a-form-item label="发送时间" name="sendTime">
          <a-date-picker
              class="item"
              v-model:value="formState.sendTime"
              :showTime="{ format: 'HH' }"
              format="YYYY-MM-DD HH:00"
              valueFormat="YYYY-MM-DD HH:00"
              placeholder="请选择"
              :disabledDate="disabledDateSendTime"
              :disabledTime="disabledTimeSendTime"
              :showToday="false"
              @openChange="sendOpenChange"
          />
        </a-form-item>
        <a-form-item label="通知内容" name="content" class="form-item-inline">
          <div style="border:1px solid #4ca5ee">
            <div>主题：【RMS系统升级通知】-上线时间{{formState.launchTime}}</div>
            <br/>
            <div style="margin-left: 5%">亲爱的RMS用户您好：</div>
            <div>RMS系统于{{formState.launchTime}}进行系统升级，升级时间在一个小时内，给您带来不便深感歉意。本次升级内容有<span class="operationLink" @click="notificationClick">（通知内容）</span></div>
            <br/>
            <div>BR</div>
            <div>CH, Staffing Management</div>
            <br/>
          </div>
          <div style="color: red" v-show="isCompleteContent">请填写通知内容</div>
        </a-form-item>
      </a-form>
    </div>
    <div class="header bottom-btn">
      <a-space>
        <a-button type="primary" ghost @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleSave()" v-if="isEdit">保存</a-button>
        <a-button type="primary" @click="handleSave()" v-else>确定</a-button>
      </a-space>
    </div>
    <NotificationContentDrawer
        v-model:visible="NotificationContentVisible"
        :isContentValue="isContentValue"
        @close-modal="closeNotificationDrawer"
        :notificationMark="notificationMark"
    />
  </a-drawer>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref, watch,
} from "vue";
import NotificationContentDrawer
  from "@/views/home/ConfigManagement/UpgradeNotice/ManualNotifications/components/NotificationContentDrawer";
import {message} from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import CardTitle from "@/components/CardTitle";
import moment from "moment";

export default defineComponent({
  name: "AddEditDrawer",
  components: {
    NotificationContentDrawer,
    CardTitle,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String
    },
    editFormData: {
      type: Object,
    }
  },
  emits: ['close-modal'],
  setup(props,{ emit }) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    const formState = reactive({
      sender: 'CH, Staffing Management',
      toEmails: [],
      ccEmails: [],
      type: '升级通知',
      launchTime: '',
      sendTime: '',
    });
    const formData = reactive({
      roleList:[],
      ccRoleList: [],
      roleInfosList:[],
    })

    const columns = [
      {
        title: "Role",
        dataIndex: "roleName",
        key: "roleName",
        ellipsis: true,
        width: 70,
      },
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        ellipsis: true,
        width: 70,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ellipsis: true,
        width: 140,
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: 50,
        slots: {
          customRender: "operation",
        },
        fixed: "right",
      },
    ];

    const rules = {
      toEmails: [
        { required: true, message: "收件人不能为空", trigger: "change", type:"array" },
      ],
      ccEmails: [
        { required: true, message: "抄送人不能为空", trigger: "change", type:"array"},
      ],
      launchTime: [
        { required: true, message: "上线时间不能为空", trigger: "change" },
      ],
      sendTime: [
        { required: true, message: "发送时间不能为空", trigger: "change" },
      ],
    };

    const isOpenToEmails = ref(false)
    const isOpenCcEmails = ref(false)
    const clickToEmails = () => {
      isOpenToEmails.value = !isOpenToEmails.value
      isOpenCcEmails.value = false
    }
    const ccClickToEmails = () => {
      isOpenCcEmails.value = !isOpenCcEmails.value
      isOpenToEmails.value = false
    }
    const isOpenEmails = () => {
      isOpenToEmails.value = false
      isOpenCcEmails.value = false
    }

    watch(
        () => isOpenToEmails.value,
        (newvalue, odlvalue) => {
          if(newvalue===false && odlvalue === true){
            formRef.value.validate('toEmails')
          }
        },
        { deep: true,  flush: 'sync' }
    )
    watch(
        () => isOpenCcEmails.value,
        (newvalue, odlvalue) => {
          if(newvalue===false && odlvalue === true){
            formRef.value.validate('ccEmails')
          }
        },
        { deep: true,  flush: 'sync' }
    )

    const filterOption = (input, option, item) => {
      console.log(input, option, item)
      return option.key.indexOf(input) >= 0 || option.children[0].children.indexOf(input) >= 0
    };

    const roleOps = ref([])
    const ccRoleOps = ref([])
    const roleInfosOps = ref([])
    const ccRoleInfosOps = ref([])
    const roleInfosOpsCopy = ref([])
    const orgAllList = ref([])

    const getRoleAndInfos = () => {
      $api.getRoleInfos().then((res) => {
        const { roleList,InfoList}  = res.data;
        roleOps.value = roleList;
        ccRoleOps.value = roleList
        roleInfosOps.value = InfoList
        roleInfosOpsCopy.value = InfoList
        ccRoleInfosOps.value = InfoList
      })
    }
    const getRoleAndInfosRelations = () => {
      $api.getRoleInfosRelations().then((res) => {
        orgAllList.value = res.data
      })
    }
    const chooseRoleList = ref([])
    watch(
        () => formData.roleList,
        (newValue,oldValue) => {
          if(newValue.length > oldValue.length) {
            const selectList = newValue.filter(item => !oldValue.includes(item))
            const select = selectList.toString()
            let newToEmails = selectList.length === 1 ? orgAllList.value.filter(item => item.roleName === select).map(item => item.fullName + " " + item.email) : roleInfosOps.value.map(item => item.fullName + " " + item.email)
            formState.toEmails = formState.toEmails.concat(newToEmails).filter((item, index, array) => {
              return array.indexOf(item) === index
            })
            let newChooseRoleList = orgAllList.value.filter(item => {
              const userStr = item.fullName + " " + item.email;
              return newToEmails.includes(userStr);
            }).filter(item => formData.roleList.indexOf(item.roleName) !== -1);
            chooseRoleList.value = chooseRoleList.value.concat(newChooseRoleList).filter((item, index, array) => {
              return array.indexOf(item) === index
            })
          } else {
            const deselectList = oldValue.filter(item => !newValue.includes(item))
            const deselect = deselectList.toString()
            let newToEmails = orgAllList.value.filter(item => item.roleName === deselect).map(item => item.fullName + " " + item.email)
            formState.toEmails = deselectList.length === 1 ? formState.toEmails.filter(item => !newToEmails.includes(item)) : []
            let newChooseRoleList = orgAllList.value.filter(item => {
              const userStr = item.fullName + " " + item.email;
              return formState.toEmails.includes(userStr);
            });
            chooseRoleList.value = chooseRoleList.value.filter(item => newChooseRoleList.includes(item))
          }
        }
    )
    const toEmailsChange = () => {
      if(formData.roleList.length > 0){
        const temp = {}
        formData.roleList.forEach(item => temp[item] = true)
        let oldRole = orgAllList.value.filter(item => temp[item.roleName]).map(item => item.fullName + " " + item.email)
        let newToEmails = formState.toEmails.filter(item => oldRole.indexOf(item) === -1)
        let newChooseRoleList = orgAllList.value.filter(item => {
          const userStr = item.fullName + " " + item.email;
          return newToEmails.includes(userStr);
        });
        chooseRoleList.value = chooseRoleList.value.concat(newChooseRoleList).filter((item, index, array)=>{return array.indexOf(item)=== index })
      }else {
        chooseRoleList.value = orgAllList.value.filter(item => {
          const userStr = item.fullName + " " + item.email;
          return formState.toEmails.includes(userStr);
        });
      }
    }
    const ccChooseRoleList = ref([])
    watch(
        () => formData.ccRoleList,
        (newValue,oldValue) => {
          if(newValue.length > oldValue.length) {
            const selectList = newValue.filter(item => !oldValue.includes(item))
            const select = selectList.toString()
            let newToEmails = selectList.length === 1? orgAllList.value.filter(item => item.roleName === select).map(item => item.fullName + " " + item.email): roleInfosOps.value.map(item => item.fullName + " " + item.email)
            formState.ccEmails = formState.ccEmails.concat(newToEmails).filter((item, index, array) => {
              return array.indexOf(item) === index
            })
            let newChooseRoleList = orgAllList.value.filter(item => {
              const userStr = item.fullName + " " + item.email;
              return newToEmails.includes(userStr);
            }).filter(item => formData.ccRoleList.indexOf(item.roleName) !== -1);
            ccChooseRoleList.value = ccChooseRoleList.value.concat(newChooseRoleList).filter((item, index, array) => {
              return array.indexOf(item) === index
            })
          } else {
            const deselectList = oldValue.filter(item => !newValue.includes(item))
            const deselect = deselectList.toString()
            let newToEmails = orgAllList.value.filter(item => item.roleName === deselect).map(item => item.fullName + " " + item.email)
            formState.ccEmails = deselectList.length === 1 ?formState.ccEmails.filter(item => !newToEmails.includes(item)): []
            let newChooseRoleList = orgAllList.value.filter(item => {
              const userStr = item.fullName + " " + item.email;
              return formState.ccEmails.includes(userStr);
            });
            ccChooseRoleList.value = ccChooseRoleList.value.filter(item => newChooseRoleList.includes(item))
          }
        }
    )
    const ccEmailsChange = () => {
      if(formData.ccRoleList.length > 0){
        const temp = {}
        formData.ccRoleList.forEach(item => temp[item] = true)
        let oldRole = orgAllList.value.filter(item => temp[item.roleName]).map(item => item.fullName + " " + item.email)
        let newToEmails = formState.ccEmails.filter(item => oldRole.indexOf(item) === -1)
        let newChooseRoleList = orgAllList.value.filter(item => {
          const userStr = item.fullName + " " + item.email;
          return newToEmails.includes(userStr);
        });
        ccChooseRoleList.value = ccChooseRoleList.value.concat(newChooseRoleList).filter((item, index, array)=>{return array.indexOf(item)=== index })
      }else {
        ccChooseRoleList.value = orgAllList.value.filter(item => {
          const userStr = item.fullName + " " + item.email;
          return formState.ccEmails.includes(userStr);
        });
      }
    }

    const delClick = (record,index) => {
      formState.toEmails = formState.toEmails.filter(item => item !== (record.fullName+" "+ record.email))
      chooseRoleList.value.splice(index, 1);
    }
    const ccDelClick = (record,index) => {
      formState.ccEmails = formState.ccEmails.filter(item => item !== (record.fullName+" "+ record.email))
      ccChooseRoleList.value.splice(index, 1);
    }

    const isEdit = computed(() => props.title === '新增发布通知')

    const NotificationContentVisible = ref(false)
    const notificationMark = ref('')
    const notificationClick = () => {
      NotificationContentVisible.value = true
    }
    const saveContentData = ref('')
    const contentParams = ref([])
    const isContentValue = ref('')
    const closeNotificationDrawer = (saveNotificationContentData,params) => {
      NotificationContentVisible.value = false
      if (props.title === '新增发布通知' && saveNotificationContentData) {
        saveContentData.value = saveNotificationContentData
        isContentValue.value = saveNotificationContentData
        contentParams.value = params
      }
      isCompleteContent.value = false
    }
    const isCompleteContent = ref(false)
    const disabledDatelaunchTime = (current) => {
      if(formState.sendTime){
        return current && current < moment(formState.sendTime).subtract(1, 'days').endOf('day')
      }else {
        return current && current < moment().subtract(1, 'days').endOf('day')
      }
    }
    const disabledTimelaunchTime = (now) => {
      if(formState.sendTime){
        const disabledDay = moment(formState.sendTime).date();
        const nowDay = moment(now).date();
        if (disabledDay === nowDay) {
          return ({
            disabledHours: () => {
              let hours = [];
              let time = moment(formState.sendTime);
              let hour = moment(time).hour() + 1;
              for (let i = 0; i < hour; i++) {
                hours.push(i);
              }
              return hours
            }
          })
        }
      }else{
        if (now) {
          const disabledTime = moment();
          const disabledDay = moment(disabledTime).date();
          const nowDay = moment(now).date();
          if (disabledDay === nowDay) {
            return ({
              disabledHours: () => {
                let hours = [];
                let time = moment();
                let hour = moment(time).hour() + 1;
                for (let i = 0; i < hour; i++) {
                  hours.push(i);
                }
                return hours
              }
            })
          }
        }
      }
      return {}
    };

    const disabledDateSendTime = (current) => {
        return current && current > moment(formState.launchTime).endOf('day')
            || current && current < moment().subtract(1, 'days').endOf('day')
    }
    const disabledTimeSendTime = (now) => {
        if (now) {
          //const disabledTime = moment();
          const disabledDay = moment(formState.launchTime).date();
          const nowDay = moment(now).date();
          if (disabledDay === nowDay && nowDay === moment().date()) {
            return ({
              disabledHours: () => {
                let hours = [];
                let time = moment(formState.launchTime);
                let hour = moment(time).hour() + 1;
                let hourNow = moment().hour();
                if (hour === hourNow) {
                  hours = [hour]
                } else {
                  for (let i = hourNow; i < hour; i++) {
                    hours.push(i);
                  }
                }
                let total = [];
                for (let i = 0; i < 24; i++) {
                  total.push(i);
                }
                let totalhour = total.filter(item => hours.indexOf(item) === -1)
                let disableHour = [...totalhour,hourNow,moment(time).hour()]
                return disableHour
              }
            })
          } else if(disabledDay === nowDay) {
            return ({
              disabledHours: () => {
                let hours = [];
                let time = moment(formState.launchTime);
                let hour = moment(time).hour();
                for (let i = hour; i < 24; i++) {
                  hours.push(i);
                }
                return hours
              }
            })
          }
        }
        if (now) {
          const disabledTime = moment();
          const disabledDay = moment(disabledTime).date();
          const nowDay = moment(now).date();
          if (disabledDay === nowDay) {
            return ({
              disabledHours: () => {
                let hours = [];
                let time = moment();
                let hour = moment(time).hour() + 1;
                for (let i = 0; i < hour; i++) {
                  hours.push(i);
                }
                return hours
              }
            })
          }
        }

        return {}
    };
    const sendOpenChange = (status) => {
      if(status === false) {
        if(moment(formState.sendTime).date() === moment().date()){
          if(moment(formState.sendTime).hour() === moment().hour()){
            formState.sendTime = ''
          }
        }
      }
    }
    const launchOpenChange = (status) => {
      if(status === false) {
        if(moment(formState.launchTime).date() === moment().date()){
          if(moment(formState.launchTime).hour() === moment().hour()){
            formState.launchTime = ''
          }
        }
      }
    }

    const handleCancel = () => {
      formRef.value.resetFields();
      isCompleteContent.value = false
      isOpenToEmails.value = false
      isOpenCcEmails.value = false
      isContentValue.value = ''
      formData.roleList=[]
      formData.ccRoleList=[]
      formData.roleInfosList=[]
      emit("close-modal");
    };

    const handleSave = () => {
      if (props.title === '新增发布通知' && !saveContentData.value) {
        isCompleteContent.value = true
      }
      Promise.all([formRef.value.validate()]).then(() => {
        const params = {
          notificationMark: saveContentData.value,
          ccEmails:formState.ccEmails,
          toEmails:formState.toEmails,
          sender:formState.sender,
          type:formState.type,
          launchTime:moment(formState.launchTime).format("YYYY-MM-DD HH:00:00"),
          sendTime:moment(formState.sendTime).format("YYYY-MM-DD HH:00:00"),
        }
        if(props.title === '新增发布通知') {
          if(saveContentData.value && contentParams.value.length !== 0) {
            $api.saveHanderNotification(params).then((res) => {
              if (res.code === 200) {
                formRef.value.resetFields();
                formData.roleList=[]
                formData.ccRoleList=[]
                formData.roleInfosList=[]
                isContentValue.value = ''
                message.success("保存成功");
                emit("close-modal");
              }
            })
          } else {
            isCompleteContent.value = true
          }
        } else {
          $api.getManualDeatisList(props.editFormData.notificationMark).then((res) => {
            if (res.data.length > 0) {
              params.id = props.editFormData.id
              $api.updateSave(params).then((res) => {
                if (res.code === 200) {
                  message.success("保存成功");
                  emit("close-modal");
                }
              })
            } else {
              isCompleteContent.value = true
            }
          })
        }
      });
    };

    watch(
        () => props.visible,
        (val) => {
          if(val) {
            if(props.editFormData){
              formState.toEmails = props.editFormData.toFullNameAndEmails
              formState.ccEmails = props.editFormData.ccFullNameAndEmails
              formState.launchTime = props.editFormData.launch_time
              formState.sendTime = props.editFormData.send_time
              notificationMark.value = props.editFormData.notificationMark
            }
            getRoleAndInfos();
            getRoleAndInfosRelations();
          }
        }
    )

    onMounted(async () => {})
    return {
      formRef,
      formState,
      formData,
      chooseRoleList,
      ccChooseRoleList,
      columns,
      rules,
      NotificationContentVisible,
      notificationMark,
      closeNotificationDrawer,
      notificationClick,
      handleCancel,
      handleSave,
      saveContentData,
      contentParams,
      isCompleteContent,
      roleOps,
      ccRoleOps,
      roleInfosOps,
      ccRoleInfosOps,
      roleInfosOpsCopy,
      getRoleAndInfos,
      orgAllList,
      getRoleAndInfosRelations,
      isOpenToEmails,
      isOpenCcEmails,
      clickToEmails,
      ccClickToEmails,
      filterOption,
      delClick,
      ccDelClick,
      disabledDateSendTime,
      disabledTimeSendTime,
      disabledDatelaunchTime,
      disabledTimelaunchTime,
      isOpenEmails,
      launchOpenChange,
      sendOpenChange,
      isContentValue,
      toEmailsChange,
      ccEmailsChange,
      isEdit,
    };
  },
});
</script>

<style lang="less" scoped>
.addEdit-drawer {
  .header {
    display: flex;
    justify-content: flex-end;
  }

  .forms {
    display: flex;
    .form {
      flex: 1;
      .items{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px 100px;
      }
    }
  }
  :deep(.select-reviewer) {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 24px;
    margin-top: 24px;

    .ant-table-wrapper {
      margin-top: 10px;
    }
  }
}
.operationLink {
  pointer-events: auto;
}
</style>
