<template>
  <section>
    <Filter @search="handleSearch" @add-click="addClick"></Filter>
    <List ref="listRef" @edit-click="editClick"></List>
    <AnnouncementDrawer
      v-if="visible"
      v-model:visible="visible"
      @close-drawer="closeDrawer"
      :title="title"
      :formData="formData"
    />
  </section>
</template>
<script>
  import { defineComponent, onActivated, ref } from "vue";
  import { useGlobalPropertyHook } from "../../../../../hooks/common";
  import Filter from "./components/Filter.vue";
  import List from "./components/List.vue";
  import AnnouncementDrawer from "./components/AnnouncementDrawer.vue";
 
  export default defineComponent({
    name: "Announcement",
    components: {
    Filter,
    List,
    AnnouncementDrawer,
  },
    
    setup(props, ctx) {
      const { $EventBus } = useGlobalPropertyHook();

      const listRef = ref();
      const visible = ref(false);
      const title = ref("")
      const formData = ref({})

      const handleSearch = (searchParams) => {
        listRef.value.searchClick(searchParams);
      };
      const refreshTable = () => {
        listRef.value.doSearch();
      };
      
      $EventBus.on("refresh", () => {
        refreshTable();
      });

      //新增
      const addClick = ()=> {
        title.value = "新增公告";
        visible.value = true;
        formData.value = {};
      }
      //编辑
      const editClick = (record)=> {
        title.value = "编辑公告";
        visible.value = true;
        formData.value = record;
        console.log(record);
      }

      const closeDrawer=()=>{
        visible.value=false;
        refreshTable();
      }

      onActivated(() => {
        ctx.emit("refresh-table");
      });
      
      return {
        listRef,
        handleSearch,
        refreshTable,
        visible,
        title,
        addClick,
        editClick,
        formData,
        closeDrawer,
      };
    },
  });
</script>
<style scoped lang="less">
  .app-container {
    background: #ffffff;
  }
</style>
  