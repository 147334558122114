<template>
    <section class="List">
      <a-table
        :columns="announcementColumns"
        :data-source="announcementList"
        :pagination="pagination"
        @change="handleTableChange"
        row-key="id"
        :scroll="{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }"
      >
        <template #status="{ text }">
          <a-tag :class="`status ${text == 0 ? '未开始' : text == 1 ? '进行中' : text == 2 ? '已完成' : text == 3 ? '已失效' :text}`">
            {{text == 0 ? "未开始" : text == 1 ? "进行中" : text == 2 ? "已完成" : text == 3 ? "已失效" :text}}
          </a-tag>
        </template>
        <template #operation="{ record }">
          <a-space class="flex-box">
<!--            <span :class="`mr16 ${record.status === '0' ? 'operationLink underline' : 'operationLink underline disabled'}`" @click="editClick(record)" v-auth="['system:upgradeNotification:announcementEdit']">编辑</span>
            <span :class="`mr16 ${record.status === '0' || record.status === '1' ? 'redLink underline' : 'redLink underline disabled'}`" @click="deactivateClick(record)" v-auth="['system:upgradeNotification:announcementDomain']">停用</span>
            <span class="operationLink underline" @click="downloadClick(record)" v-auth="['system:upgradeNotification:announcementDownload']">下载</span>-->
            <a-tooltip class="tooltip-list" placement="top" color="#fff">
              <template #title>
                <span class="font-color">编辑</span>
              </template>
              <span
                  :class="`mr16 ${record.status === '0' ? 'operationLink underline icon-btn' : 'operationLink underline disabled icon-btn'}`"
                  @click="editClick(record)"
                  v-auth="['system:upgradeNotification:announcementEdit']">
                <FormOutlined :style="{display:'block'}" />
              </span>
            </a-tooltip>
            <a-tooltip class="tooltip-list" placement="top" color="#fff">
              <template #title>
                <span class="font-color">停用</span>
              </template>
              <span :class="`mr16 ${record.status === '0' || record.status === '1' ? 'redLink underline icon-btn' : 'redLink underline disabled icon-btn'}`" @click="deactivateClick(record)" v-auth="['system:upgradeNotification:announcementDomain']">
                <MinusCircleOutlined :style="{display:'block'}" />
              </span>
            </a-tooltip>
            <a-tooltip class="tooltip-list" placement="top" color="#fff">
              <template #title>
                <span class="font-color">下载</span>
              </template>
              <span class="operationLink underline icon-btn" @click="downloadClick(record)" v-auth="['system:upgradeNotification:announcementDownload']">
                <DownloadOutlined :style="{display:'block'}" />
              </span>
            </a-tooltip>
          </a-space>
        </template>
      </a-table>
    </section>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
  </template>

  <script>
  import { defineComponent, ref, reactive, nextTick, onMounted, onUnmounted } from "vue";
  import { useTableHeight, downloadFile } from "@/utils/common";
  import { useGlobalPropertyHook } from "@/hooks/common.js";
  import { message } from "ant-design-vue";
  import { FormOutlined, MinusCircleOutlined, DownloadOutlined } from "@ant-design/icons-vue";
  import { useStore } from "vuex";
  import ModalBox from '@/components/ModalBox'
  export default defineComponent({
    name: "announcementList",
    components: {ModalBox, FormOutlined, MinusCircleOutlined, DownloadOutlined},
    emit: ["edit-click"],

    setup(props,ctx) {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();

    const editClick = (record) => {
        if(record.status !== '0') return false
        ctx.emit("edit-click", record);
      };
      const announcementColumns = [
        {
          title: "公告内容",
          dataIndex: "content",
          key: "content",
          ellipsis: true,
        },
        {
          title: "开始时间",
          dataIndex: "start_time",
          key: "start_time",
          width: 200,
          ellipsis: true,
          sorter: true,
        },
        {
          title: "结束时间",
          dataIndex: "end_time",
          key: "end_time",
          width: 200,
          ellipsis: true,
          sorter: true,
        },
        {
          title: "公告类型",
          dataIndex: "type",
          key: "type",
          width: 100,
          ellipsis: true,
          customRender: ({ text }) => (text == 0 ? "页面通知" : text == 1 ? "页面锁定" : text),
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: 80,
          ellipsis: true,
          //customRender: ({ text }) => (text == 0 ? "未开始" : text == 1 ? "进行中" : text == 2 ? "已完成" : text == 3 ? "已失效" :text),
          slots: { customRender: "status" },
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          key: "update_time",
          width: 200,
          ellipsis: true,
          sorter: true,
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          slots: { customRender: "operation" },
          width: 140,
          fixed: "right"
        },
      ];

      const pagination = reactive({
        current: 1,
        pageSize: 11,
        pageSizeOptions: ['11','20','30','40'],
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
        onChange: (page) => {
          pagination.current = page;
          doSearch(searchCondition);
        },
        onShowSizeChange: (_, size) => {
          pagination.pageSize = size;
          pagination.current = 1;
          doSearch(searchCondition);
        },
      });

      const announcementList = ref([]);
      let searchCondition = ref({});

      const tableHeight = ref(0);
      const getSize = () => {
        nextTick(() => {
          tableHeight.value = useTableHeight();
        });
      };

    const doSearch = () => {
      const{ startTime, endTime}=searchCondition.value
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        type: searchCondition.value.announcementType,
        status: searchCondition.value.announcementStatus,
        startTime,
        endTime,
        sort,
      }
      $api.postAnnouncementList(params).then((res) => {
        pagination.total = res.data.total;
        announcementList.value = res.data.resource;
      });
    };
    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition.value = searchParams;
      doSearch();
    };

    // 排序
    const sort = reactive({
      field: '',
      order: '',
    });
    const handleTableChange = (pag, filters, sorter) => {
      if(sorter.order === 'descend'){ sorter.order='ascend'}
      else if(sorter.order === 'ascend'){ sorter.order='descend'}
      if (!sorter.order) {
        sorter.order = ""
        sorter.field = ""
      }
      Object.assign(sort, sorter)
      doSearch();
    };
      const modelValue = ref(false)
      const content = ref('')
      const showIconCom = ref('')
      const modalData = ref()
      const handleOk = () => {
        $api.getAnnouncementDeactivate(modalData.value.id).then((res) => {
          if (res.code === 200) {
            store.dispatch("updateUpgradeAnnouncement");
            message.success("停用成功");
            doSearch()
          } else {
            message.error("停用失败");
          }
        })
      }
    //停用
    const deactivateClick = (record) => {
      if(!(record.status === '0' || record.status === '1')) return false
      content.value = '请确定是否停用该公告'
      showIconCom.value = 'ExclamationCircleOutlined'
      modalData.value = record
      modelValue.value = true
      /*Modal.confirm({
        title: () => '提示',
        content: "请确定是否停用该公告",
        width: 250,
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          $api.getAnnouncementDeactivate(record.id).then((res) => {
            if (res.code === 200) {
              store.dispatch("updateUpgradeAnnouncement");
              message.success("停用成功");
              doSearch()
            } else {
              message.error("停用失败");
            }
          })
        }
      })*/
    }

    //下载
    const downloadClick= (record) => {
      const params = {
        id: record.id,
        type: record.type,
      }
      $api.postAnnouncementDownload(params).then((res) => {
        downloadFile(res.file, res.filename);
      })
    }

      window.addEventListener("resize", getSize);

      onUnmounted(() => {
        window.removeEventListener("resize", getSize);
      });

      onMounted(() => {
        getSize();
      });

      return {
        announcementList,
        announcementColumns,
        pagination,
        tableHeight,
        doSearch,
        searchClick,
        handleTableChange,
        deactivateClick,
        editClick,
        downloadClick,
        modelValue,
        content,
        showIconCom,
        handleOk,
      };
    },
  });
  </script>

  <style scoped lang="less">
   .flex-box{
     display: flex;
     .icon-btn {
       display: flex;
       width: 24px;
       height: 24px;
       :deep(svg) {
         width: 100%;
         height: 100%;
       }
     }
   }
   .operationLink, .redLink{
     pointer-events: auto;
   }
   .font-color{
     color:#4d4d4d;
   }
   .status{
     font-size: 0.729vw;
     padding: 0.1vw 0.86vw;
     border: 0;

     &.未开始 {
       color: #1890FF;
       background: #DBEEFF;
     }
     &.进行中 {
       color: #58B99E;
       background: #DDF8F0 60%;
     }
     &.已完成 {
       color: #999999;
       background: #CCCCCC 60%;
     }
     &.已失效 {
       color: #DC5E4F;
       background: #FFE7EC;
     }
   }

  </style>
