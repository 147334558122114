<template>
    <a-drawer
      class="header"
      :title="title"
      width="38.5vw"
      :visible="visible"
      @closeDrawer="handleCancel"
      @update:visible="(val) => $emit('close-drawer', val)"
    >
      <div class="forms">
        <a-form class="form" layout="vertical" ref="formRef" :rules="formRules" :model="formState">
          <a-form-item label="公告主题" name="announcementTitle">
            <a-select
              v-model:value="formState.announcementTitle"
              placeholder="请选择"
              allow-clear
              @change="relatedTitle"
            >
              <a-select-option
                v-for="(item, index) in announcementTitleList"
                :key="index"
                :value="item.id">
                <span :title="item.emailTitle">{{ item.emailTitle }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="收件人" name="toEmails">
            <a-select
              v-model:value="formState.toEmails"
              placeholder="请选择"
              disabled
            >
            </a-select>
          </a-form-item>
          <a-form-item label="抄送人" name="ccEmails">
            <a-select
              v-model:value="formState.ccEmails"
              placeholder="请选择"
              disabled
            >
            </a-select>
          </a-form-item>
          <div class="items">
          <a-form-item label="公告时间" name="announcementDate">
            <a-range-picker
              v-model:value="formState.announcementDate"
              :show-time="{ format: 'HH' }"
              format="YYYY-MM-DD HH:00"
              :placeholder="['开始时间', '结束时间']"
              :disabled-date="disabledDate"
              :disabled-time="disabledDateTime"
              @change="relatedContent"
              :showToday="false"
              @openChange="openChange"
            />
          </a-form-item>
          <a-form-item label="公告类型" name="type">
            <a-select
              v-model:value="formState.type"
              placeholder="请输入"
              allow-clear
              @change="relatedContent"
            >
              <a-select-option value="0">页面通知</a-select-option>
              <a-select-option value="1">页面锁定</a-select-option>
            </a-select>
          </a-form-item>
        </div>
          <a-form-item label="公告内容" name="content">
            <a-textarea
              v-model:value="formState.content"
              placeholder="请输入"
              disabled
            />
          </a-form-item>
        </a-form>
      </div>
      <div class="btn bottom-btn">
        <a-space>
          <a-button type="primary" ghost @click="handleCancel">取消</a-button>
          <a-button type="primary"  @click="handleSave">保存</a-button>
        </a-space>
      </div>
    </a-drawer>
  </template>

  <script>
  import { defineComponent, onMounted, reactive, ref } from "vue";
  import { useGlobalPropertyHook } from "@/hooks/common.js";
  import moment from "moment";
  import { message } from "ant-design-vue";

  export default defineComponent({
    name: "AccountDrawer",
    components: {},

    emits: ["close-drawer"],
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: "",
      },
      formData: Object,
    },
    setup(props, { emit }) {
      const { $api } = useGlobalPropertyHook();

      const formRef = ref();
      const formState = reactive({
        announcementTitle: undefined,
        toEmails: undefined,
        ccEmails: undefined,
        announcementDate: [],
        startTime: "",
        endTime: "",
        type: undefined,
        content: "",
      });

      const formRules = {
        announcementTitle: [{ required: true, message: "公告主题不能为空", trigger: "blur" }],
        toEmails: [{ required: true, message: "收件人不能为空", trigger: "change" }],
        ccEmails: [{ required: true, message: "抄送人不能为空", trigger: "change" }],
        announcementDate: [{ required: true, message: "公告时间不能为空", trigger: ["change", "blur"], type: "array", }],
        type: [{ required: true, message: "公告类型不能为空", trigger: "blur" }],
        content: [{ required: true, message: "公告内容不能为空", trigger: "blur" }],
      }

      //只能选择当前及之后的day
      const disabledDate = (current) => {
        return current && current < moment().subtract(1, "days").endOf('day');
      };

      const range = (start, end) => {
        const result = [];
        for (let i=start; i<=end; i++) {
          result.push(i);
        }
        return result;
      };
      //只能选择当前及之后的hour
      const disabledDateTime = (dates,partial) => {
        let hours = moment().hours();
        if(dates&&moment(dates[0]).date() === moment().date()&&partial=='start') {
          return{
            disabledHours: () => range(0, hours)
          }
        }
      }

      //获取公告主题
      const announcementTitleList = ref([]);
      const getAnnouncementTitle = () => {
        $api.getManualNotification().then((res) => {
          announcementTitleList.value = res.data
        })
      }

      //获取公告主题的收件人、抄送人
      const relatedTitle = (row) => {
        const value = announcementTitleList.value.find((it) => {
           return it.id === row
        })
        $api.getEditManual({notificationMark:value.notificationMark}).then(res=>{
          formState.toEmails = res.data? res.data.toEmails : ''
          formState.ccEmails = res.data? res.data.ccEmails : ''
        })
        showContentMethod()
      }

      const relatedContent = () => {
        showContentMethod();
      }

      //带出content
      const showContentMethod = () => {
        if (formState.announcementDate.length && formState.type && formState.announcementTitle) {
          const title = announcementTitleList.value.find((it) => {
            return it.id === formState.announcementTitle
          })
          if (formState.type === '1') {
            formState.content = title.emailTitle + '，系统暂无法使用，给您带来不便深感歉意。'
          } else  formState.content = title.emailTitle + '，给您带来不便深感歉意。'
        } else formState.content = ''
      }

      //保存
      const handleSave = () => {
        //判断编辑or新增
        let apiName = props.title === '编辑公告'?'postAnnouncementEdit':'postAnnouncementSave'
        //获取时间值
        let startTime, endTime
        startTime =  moment(formState.announcementDate[0]).format("YYYY-MM-DD HH:00:00")
        endTime = moment(formState.announcementDate[1]).format("YYYY-MM-DD HH:00:00")
        const params = {
          content: formState.content,
          manualNotificationId: formState.announcementTitle,
          toEmails: formState.toEmails,
          ccEmails: formState.ccEmails,
          startTime,
          endTime,
          type: formState.type,
        }
        //判断是否传id
        const searchParams = props.title === '编辑公告'? {...params, id: props.formData.id}:params
        $api[apiName](searchParams).then((res) => {
          if(res.code === 200){
            message.success('成功');
            emit("close-drawer");
          }
        })
      }

      //若为当前时间，关闭时间选择框后，赋空
      const openChange = (status) => {
        if(status === false) {
          if(moment(formState.announcementDate[0]).date() === moment().date()){
            if(moment(formState.announcementDate[0]).hour() === moment().hour()){
              formState.announcementDate = ''
            }
          }
        }
      }

      const handleCancel = () => {
        emit("close-drawer");
      };

      onMounted(() => {
        getAnnouncementTitle();
        formState.announcementTitle = props.formData.manual_notification_id
        formState.toEmails = props.formData.to_emails
        formState.ccEmails = props.formData.cc_emails
        if(props.title === '编辑公告'){
          formState.announcementDate[0] = moment(props.formData.start_time)
          formState.announcementDate[1] = moment(props.formData.end_time)
        }else{
          formState.announcementDate = []
        }
        formState.type = props.formData.type
        formState.content = props.formData.content
        formState.id = props.formData.id
      });

      return {
        formRef,
        formState,
        formRules,
        handleCancel,
        announcementTitleList,
        getAnnouncementTitle,
        relatedTitle,
        relatedContent,
        disabledDate,
        disabledDateTime,
        range,
        handleSave,
        openChange
      };
    },
  });
  </script>

  <style lang="less" scoped>
    :deep(.ant-calendar-picker){
      width: 100% !important;
    }
    .header {
      .btn{
        text-align: right;
      }
    }
    .forms {
      .form {
        .items{
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 0px 100px;
        }

      }
    }
  </style>
