<template>
    <section class="top-filter">
        <FilterLayout @doReset="resetForm"
                      @doSearch="doSearch"
                      ref="filterLayoutRef">
          <template #customform>
            <a-form
                ref="formRef"
                class="form rms-form"
                name="custom-validation"
                layout="vertical"
                :model="formState"
            >
              <a-form-item label="公告时间" name="announcementDate" class="rms-form-item">
                <a-range-picker
                    v-model:value="formState.announcementDate"
                    :showTime="{ format: 'HH' }"
                    format="YYYY-MM-DD HH:00"
                    :placeholder="['Start date', 'End date']"
                />
              </a-form-item>
              <a-form-item label="公告类型" name="announcementType" class="rms-form-item">
                <SelectWithAll
                    v-model:value="formState.announcementType"
                    :options="announcementTypeOps"
                    placeholder="请选择"
                    mode="multiple"
                    showArrow
                    allowClear
                    :maxTagCount="1"
                    :tag-count="2"
                >
                </SelectWithAll>
              </a-form-item>
              <a-form-item  label="状态" name="announcementStatus" class="rms-form-item">
                <SelectWithAll
                    v-model:value="formState.announcementStatus"
                    :options="announcementStatusOps"
                    placeholder="请选择"
                    mode="multiple"
                    showArrow
                    allowClear
                    :maxTagCount="1"
                    :tag-count="3"
                >
                </SelectWithAll>
              </a-form-item>
            </a-form>
          </template>
          <template #operationBtns>
            <div class="buttons-wrap right">
              <Tooltip title="新增">
                <a-button class="" type="primary" @click="addClick" v-auth="['system:upgradeNotification:announcementAdd']">
                  <PlusOutlined />
                </a-button>
              </Tooltip>
            </div>
          </template>
        </FilterLayout>
    </section>
  </template>
  <script>
  import { defineComponent, reactive, ref, onMounted, nextTick,watch } from "vue";
  import { useGlobalPropertyHook } from "@/hooks/common.js";
  import { cloneDeep } from "lodash";
  import { PlusOutlined } from "@ant-design/icons-vue";
  import Tooltip from '@/components/Tooltip'

  export default defineComponent({
    name: "Filter",
    components: {Tooltip, PlusOutlined },
    emits: ["search", "add-click"],
    setup(props, ctx) {
      const { $EventBus } = useGlobalPropertyHook();

      const addClick = () => {
        ctx.emit("add-click");
      };
        const formRef = ref();
        const formState = reactive({
            announcementType: [],
            announcementStatus: [],
            announcementDate: [],
            startTime: "",
            endTime: "",
        });
        const announcementTypeOps = ref([
            {
                value: 0,
                label: "页面通知",
            },
            {
                value: 1,
                label: "页面锁定",
            }
        ]);
        const announcementStatusOps = ref([
            {
                value: 0,
                label: "未开始",
            },
            {
                value: 1,
                label: "进行中",
            },
            {
                value: 2,
                label: "已完成",
            },
            {
                value: 3,
                label: "已失效",
            },
        ]);
        let currentState = {};
        const updateParams = () => {
            currentState = cloneDeep(formState);
        };
        const resetForm = () => {
            formRef.value.resetFields();
            formState.startTime='';
            formState.endTime='';
            updateParams();
            ctx.emit("search", currentState);
        };
        $EventBus.on("refresh", () => {
          resetForm();
        });
        const doSearch = () => {
            updateParams();
            ctx.emit("search", currentState);
        };
        onMounted(() => {
            nextTick(doSearch);
        });
      watch(
        () => formState.announcementDate,
        (value) => {
          if (value) {
            formState.startTime = value[0] ? value[0].format("YYYY-MM-DD HH") : null; formState.endTime = value[1] ? value[1].format("YYYY-MM-DD HH") : null;
          }
        }
      )
        return {
            formState,
            formRef,
            doSearch,
            resetForm,
            updateParams,
            announcementTypeOps,
            announcementStatusOps,
            addClick,
        };
    },

});
</script>
<style scoped lang="less">
:deep(.ant-calendar-picker){
  width: 100% !important;
}
.top-filter {
  width: 100%;
  .search {
    text-align: right;
  }
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
