<template>
    <section class="List">
      <a-table
        :columns="columns"
        :data-source="list"
        :pagination="pagination"
        @change="handleTableChange"
        row-key="id"
        :customRow="customRowFunc"
        :scroll="{
          y: tableHeight,
        }"
      >
        <template #status="{ text }">
          <a-tag :class="`status ${text == 0 ? '未开始' : text == 1 ? '已发送' : text == 2 ? '已失效' : text}`">
            {{text == 0 ? "未开始" : text == 1 ? "已发送" : text == 2 ? "已失效" : text}}
          </a-tag>
        </template>
        <template #operation="{ record }">
<!--          <a-space>
            <span :class="`mr16 ${record.status === '0' ? 'operationLink underline' : 'operationLink underline disabled'}`" @click="editClick(record)" v-auth="['system:upgradeNotification:manualEdit']">编辑</span>
            <span :class="`mr16 ${record.status === '0' ? 'redLink underline' : 'redLink underline disabled'}`" @click="deactivateClick(record)" v-auth="['system:upgradeNotification:manualDomain']">停用</span>
            <span class="operationLink underline" @click="downloadClick(record)" v-auth="['system:upgradeNotification:manualDownload']">下载</span>
          </a-space>-->
          <ActionBtn class="btnList" v-bind="record" :btnList="btnList" @FormOutlined="editClick(record)" @MinusCircleOutlined="deactivateClick(record)" @DownloadOutlined="downloadClick(record)" />
        </template>
      </a-table>
      <DetailsDrawer
        v-if="detailsDrawerVisible"
        v-model:visible="detailsDrawerVisible"
        @close-modal="closeDetailsDrawer"
        :details-drawer="activeRow"
        :id="notificationMark"
      />
      <AddEditDrawer
          v-model:visible="AddEditDrawerVisible"
          :editFormData="formData"
          title="编辑发布通知"
          @close-modal="closeAddEditDrawer"
      />
    </section>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
  </template>

  <script>
  import { defineComponent, ref, reactive, nextTick, onMounted, onUnmounted } from "vue";
  import { useTableHeight } from "@/utils/common";
  import { useGlobalPropertyHook } from "@/hooks/common.js";
  import DetailsDrawer from "./DetailsDrawer.vue";
  import { message } from "ant-design-vue";
  import { downloadFile } from "@/utils/common";
  import AddEditDrawer from "@/views/home/ConfigManagement/UpgradeNotice/ManualNotifications/components/AddEditDrawer";
  import ModalBox from '@/components/ModalBox'
  import ActionBtn from '@/components/ActionBtn'

  const btnList = [
    {
      name: '编辑',
      icon: 'FormOutlined',
      auth: ['system:upgradeNotification:manualEdit']
    },
    {
      name: '停用',
      icon: 'MinusCircleOutlined',
      auth: ['system:upgradeNotification:manualDomain']
    },
    {
      name: '下载',
      icon: 'DownloadOutlined',
      auth: ['system:upgradeNotification:manualDownload']
    },
  ]
  export default defineComponent({
    name: "list",
    components: {AddEditDrawer, DetailsDrawer, ModalBox, ActionBtn },

    setup() {
    const { $api } = useGlobalPropertyHook();

      const columns = [
        {
          title: "发件人",
          dataIndex: "sender",
          key: "sender",
          ellipsis: true,
        },
        {
          title: "收件人",
          dataIndex: "toEmails",
          key: "toEmails",
          ellipsis: true,
        },
        {
          title: "抄送人",
          dataIndex: "ccEmails",
          key: "ccEmails",
          ellipsis: true,
        },
        {
          title: "上线时间",
          dataIndex: "launch_time",
          key: "launch_time",
          ellipsis: true,
          sorter: true,
        },
        {
          title: "发送时间",
          dataIndex: "send_time",
          key: "send_time",
          ellipsis: true,
          sorter: true,
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          width: 80,
          ellipsis: true,
          slots: { customRender: "status" },
        },
        {
          title: "通知类型",
          dataIndex: "type",
          key: "type",
          width: 100,
          ellipsis: true,
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          key: "update_time",
          ellipsis: true,
          sorter: true,
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          slots: { customRender: "operation" },
          width: 140,
          fixed: "right",
        },
      ];

      const pagination = reactive({
        current: 1,
        pageSize: 11,
        pageSizeOptions: ['11','20','30','40'],
        total: 0,
        showSizeChanger: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
        onChange: (page) => {
          pagination.current = page;
          doSearch(searchCondition);
        },
        onShowSizeChange: (_, size) => {
          pagination.pageSize = size;
          pagination.current = 1;
          doSearch(searchCondition);
        },
      });

      const list = ref([]);
      let searchCondition = ref({});

      const tableHeight = ref(0);
      const getSize = () => {
        nextTick(() => {
          tableHeight.value = useTableHeight();
        });
      };

    const doSearch = () => {
      const{ launchTimeBegin, launchTimeEnd, sendTimeBegin, sendTimeEnd, updateTimeBegin, updateTimeEnd,}=searchCondition.value
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        status: searchCondition.value.status,
        launchTimeBegin,
        launchTimeEnd,
        sendTimeBegin,
        sendTimeEnd,
        updateTimeBegin,
        updateTimeEnd,
        sort,
      }
      $api.postManualNotifications(params).then((res) => {
        pagination.total = res.data.total;
        list.value = res.data.resource;
      });
    };
    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition.value = searchParams;
      doSearch();
    };

    // 排序
    const sort = reactive({
      field: '',
      order: '',
    });
    const handleTableChange = (pag, filters, sorter) => {
      if(sorter.order === 'descend'){ sorter.order='ascend'}
      else if(sorter.order === 'ascend'){ sorter.order='descend'}
      if (!sorter.order) {
        sorter.order = ""
        sorter.field = ""
      }
      Object.assign(sort, sorter)
      doSearch();
    };

    //通知内容详情页
    const detailsDrawerVisible = ref(false)
    const activeRow = ref({
      id: "",
    });
    const notificationMark=ref('')
    const customRowFunc = (record) => {
      return {
        onClick: (event) => {
          console.log(event.target.innerText, '=========')
          //操作列点击后不可弹出详通知内容
          if(['编辑','停用','下载'].includes(event.target.innerText)){
            return
          }
          if(event.target.innerText === undefined){
            return
          }
          notificationMark.value = record.notificationMark
          detailsDrawerVisible.value = true
        },
      };
    };
    const closeDetailsDrawer = () => {
      detailsDrawerVisible.value = false
    }
    const editClick = (record) => {
      $api.getEditManual({notificationMark:record.notificationMark}).then(res=>{
        formData.value = res.data
        AddEditDrawerVisible.value = true
      })
    }
    const formData = ref()
    const AddEditDrawerVisible = ref(false)
    const closeAddEditDrawer = () => {
      AddEditDrawerVisible.value = false
      doSearch(searchCondition);
    }

      const modelValue = ref(false)
      const content = ref('')
      const showIconCom = ref('')
      const modalData = ref()
      const handleOk = () => {
        $api.getmanualnotificationdeactivate(modalData.value.id)
            .then((res) => {
              if (res.code === 200) {
                message.success("停用成功");
                doSearch()
              } else {
                message.error("停用失败");
              }
            });
      }

      const deactivateClick = (record) => {
        content.value = '请确定是否停用该条发布通知？'
        showIconCom.value = 'ExclamationCircleOutlined'
        modalData.value = record
        modelValue.value = true
        /*Modal.confirm({
        title: () => '提示',
        content: "请确定是否停用该条发布通知？",
        width: 300,
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          $api
              .getmanualnotificationdeactivate(record.id)
              .then((res) => {
                if (res.code === 200) {
                  message.success("停用成功");
                  doSearch()
                } else {
                  message.error("停用失败");
                }
              });
        },
      });*/
      }
      const downloadClick = (record) => {
        const data={
          id:record.id,
          sendTime:record.send_time
        }
        $api.staffannouncementlist(data).then(res=>{
          downloadFile(res.file, `手动通知_${record.send_time}.xls`);
        })
      }

      window.addEventListener("resize", getSize);

      onUnmounted(() => {
        window.removeEventListener("resize", getSize);
      });

      onMounted(() => {
        getSize();
      });
      return {
        list,
        columns,
        pagination,
        tableHeight,
        doSearch,
        searchClick,
        handleTableChange,
        customRowFunc,
        detailsDrawerVisible,
        closeDetailsDrawer,
        activeRow,
        notificationMark,
        deactivateClick,
        downloadClick,
        editClick,
        AddEditDrawerVisible,
        closeAddEditDrawer,
        formData,
        modelValue,
        content,
        showIconCom,
        handleOk,
        btnList
      };
    },
  });
  </script>
<style lang="less" scoped>
.status{
  font-size: 0.729vw;
  padding: 0.1vw 0.86vw;
  border: 0;

  &.未开始 {
    color: #1890FF;
    background: #DBEEFF;
  }
  &.已发送 {
    color: #58B99E;
    background: #DDF8F0 60%;
  }
  &.已失效 {
    color: #DC5E4F;
    background: #FFE7EC;
  }
}

</style>
