<template>
  <section>
    <Filter @search="handleSearch"></Filter>
    <List ref="listRef"></List>
  </section>
</template>
<script>
import { defineComponent, onActivated, ref } from "vue";
import { useGlobalPropertyHook } from "../../../../../hooks/common";
import Filter from "./components/Filter.vue";
import List from "./components/List.vue";

export default defineComponent({
  name: "Announcement",
  components: {
    Filter,
    List,
  },
  
  setup(props, ctx) {
    const listRef = ref();
    let visible = ref(true);
    const { $EventBus } = useGlobalPropertyHook();

    const handleSearch = (searchParams) => {
      listRef.value.searchClick(searchParams);
    };
    const refreshTable = () => {
      listRef.value.doSearch();
    };
    
    $EventBus.on("refresh", () => {
      refreshTable();
    });

    onActivated(() => {
      ctx.emit("refresh-table");
    });
    
    return {
      listRef,
      handleSearch,
      refreshTable,
      visible,
    };
  },
});
</script>

