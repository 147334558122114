<template>
  <section class="top-filter">
    <FilterLayout @doReset="resetForm"
                  @doSearch="doSearch"
                  ref="filterLayoutRef">
      <template #customform>
        <a-form
            ref="formRef"
            class="form rms-form"
            name="custom-validation"
            layout="vertical"
            :model="formState"
        >
          <a-form-item label="上线时间" name="launchTime" class="rms-form-item">
            <a-range-picker
                class="item"
                v-model:value="formState.launchTime"
                :showTime="{ format: 'HH' }"
                format="YYYY-MM-DD HH:00"
                :placeholder="['Start date', 'End date']"
            />
          </a-form-item>
          <a-form-item label="发送时间" name="sendTime" class="rms-form-item">
            <a-range-picker
                class="item"
                v-model:value="formState.sendTime"
                :showTime="{ format: 'HH' }"
                format="YYYY-MM-DD HH:00"
                :placeholder="['Start date', 'End date']"
            />
          </a-form-item>
          <a-form-item label="更新时间" name="updateTime" class="rms-form-item">
            <a-range-picker
                class="item"
                v-model:value="formState.updateTime"
                :showTime="{ format: 'HH' }"
                format="YYYY-MM-DD HH:00"
                :placeholder="['Start date', 'End date']"
            />
          </a-form-item>
          <a-form-item  label="状态" name="status" class="rms-form-item">
            <SelectWithAll
                class="item"
                v-model:value="formState.status"
                :options="statusOps"
                placeholder="请选择"
                mode="multiple"
                showArrow
                allowClear
                :maxTagCount="1"
                :tag-count="3"
            >
            </SelectWithAll>
          </a-form-item>
        </a-form>
      </template>
      <template #operationBtns>
        <div class="buttons-wrap right">
          <Tooltip title="新增">
            <a-button class="" type="primary" @click="addClick" v-auth="['system:upgradeNotification:manualAdd']">
              <PlusOutlined />
            </a-button>
          </Tooltip>
        </div>
      </template>
    </FilterLayout>
    <AddEditDrawer
        v-model:visible="AddEditDrawerVisible"
        title="新增发布通知"
        @close-modal="closeAddEditDrawer"
    />
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, nextTick,watch } from "vue";
import { cloneDeep } from "lodash";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import AddEditDrawer from "@/views/home/ConfigManagement/UpgradeNotice/ManualNotifications/components/AddEditDrawer";
import { PlusOutlined } from "@ant-design/icons-vue";
import Tooltip from '@/components/Tooltip'

export default defineComponent({
  name: "Filter",
  components: {Tooltip, AddEditDrawer, PlusOutlined },
  emits: ["search"],
  setup(props, ctx) {
      const { $EventBus } = useGlobalPropertyHook();

      const formRef = ref();
      const formState = reactive({
          status: [],
          launchTime: [],
          sendTime: [],
          updateTime: [],
          launchTimeBegin: "",
          launchTimeEnd: "",
          sendTimeBegin: "",
          sendTimeEnd: "",
          updateTimeBegin: "",
          updateTimeEnd:"",
      });
      const statusOps = ref([
          {
              value: 0,
              label: "未开始",
          },
          {
              value: 1,
              label: "已发送",
          },
          {
              value: 2,
              label: "已失效",
          },
      ]);
      let currentState = {};
      const updateParams = () => {
          currentState = cloneDeep(formState);
      };
      const resetForm = () => {
          formRef.value.resetFields();
          formState.launchTimeBegin='',
          formState.launchTimeEnd='',
          formState.sendTimeBegin='',
          formState.sendTimeEnd='',
          formState.updateTimeBegin='',
          formState.updateTimeEnd='',
          updateParams();
          ctx.emit("search", currentState);
      };

      $EventBus.on("refresh", () => {
        resetForm();
      });

      const doSearch = () => {
          updateParams();
          ctx.emit("search", currentState);
      };
      onMounted(() => {
          nextTick(doSearch);
      });
    watch(
      () => formState.launchTime,
      (value) => {
        if (value) {
          formState.launchTimeBegin = value[0] ? value[0].format("YYYY-MM-DD HH") : null;
          formState.launchTimeEnd = value[1] ? value[1].format("YYYY-MM-DD HH") : null;
        }
      }
    )
    watch(
      () => formState.sendTime,
      (value) => {
        if (value) {
          formState.sendTimeBegin = value[0] ? value[0].format("YYYY-MM-DD HH") : null;
          formState.sendTimeEnd = value[1] ? value[1].format("YYYY-MM-DD HH") : null;
        }
      }
    )
    watch(
      () => formState.updateTime,
      (value) => {
        if (value) {
          formState.updateTimeBegin = value[0] ? value[0].format("YYYY-MM-DD HH") : null;
          formState.updateTimeEnd = value[1] ? value[1].format("YYYY-MM-DD HH") : null;
        }
      }
    )
    const AddEditDrawerVisible = ref(false)
    const addClick = () => {
      AddEditDrawerVisible.value = true
    }
    const closeAddEditDrawer = () => {
      AddEditDrawerVisible.value = false
      doSearch()
    }

      return {
        formState,
        formRef,
        doSearch,
        resetForm,
        updateParams,
        statusOps,
        addClick,
        AddEditDrawerVisible,
        closeAddEditDrawer,
      };
  },

});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
  .item {
    width: 100% !important;
  }
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
